import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { setTemporaryPassword } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isSettingUser, setIsSettingUser] = useState(false);
	const [error, setError] = useState(null);
	const [hasSetUser, setHasSetUser] = useState(false);
	const [response, setResponse] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	return {
		isSettingUser,
		hasSetUser,
		response,
		error,
		setTempPassword: (userId, password) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsSettingUser(true);
					return setTemporaryPassword(token, userId, password);
				})
				.then((response) => {
					setResponse(response);
					setHasSetUser(true);
				})
				.catch((e) => {
					setError(e);
					console.error(e);
				})
				.finally(() => {
					setIsSettingUser(false);
				});
		},
	};
};
