import { useAuth0 } from "@auth0/auth0-react";
import Box from "@mui/material/Box";
import { useEffect, useSyncExternalStore } from "react";
import { handleGetAccessToken } from "../../Helpers/auth";
import { store as dashboardStore } from "../../Stores/Dashboard";
import { actions as meActions } from "../../Stores/Me";
import LookerEmbed from "./components/LookerEmbed";
import "./styles.css";
import { useSearchParams } from "react-router-dom";

export default () => {
	const [searchParams] = useSearchParams();
	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const namespace = searchParams.get("n");

	const {
		error: fetchDashboardsError,
		isFetching: fetchingDashboards,
		isFetched: fetchedDashboards,
	} = useSyncExternalStore(
		dashboardStore.subscribeToDashboards,
		dashboardStore.getDashboards,
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (!fetchedDashboards) {
			handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
				(token) => {
					meActions.fetchMe(token);
					meActions.fetchDashboards(token);
				},
			);
		}
	}, [getAccessTokenSilently, loginWithRedirect]);

	return (
		<>
			<Box flexGrow={1} overflow="hidden" height="100%">
				{fetchDashboardsError ? (
					<p id="error">Something went wrong</p>
				) : (
					<LookerEmbed
						showLoading={!fetchedDashboards || fetchingDashboards}
						namespace={namespace}
					/>
				)}
			</Box>
		</>
	);
};
