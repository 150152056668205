import Avatar from "@mui/material/Avatar";

export default ({
	width = "28px",
	height = "28px",
	fontSize = "1em",
	letters = "",
	backgroundColor = "#65c8e9",
	sx = {},
	...rest
}) => {
	return (
		<Avatar
			sx={{
				width,
				height,
				backgroundColor,
				...sx,
			}}
			{...rest}
		>
			<span style={{ fontSize }}>{letters ?? ""}</span>
		</Avatar>
	);
};
