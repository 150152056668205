import { Visibility, VisibilityOff } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
	FormControlLabel,
	FormHelperText,
	IconButton,
	InputAdornment,
	Stack,
	Switch,
	TextField,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "../styles.css";
import SendIcon from "@mui/icons-material/Send";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useEffect, useState } from "react";
import ChangePasswordDialog from "./ChangePasswordDialog";
import useSendReset from "./useSendReset";
import useSetTempPassword from "./useSetTempPassword";

const EMPTY_HELPER_TEXT = " ";

//these rules come from auth0 guidelines
function getTemporaryPasswordError(password) {
	if (password.length < 8) {
		return "Password must be at least 8 characters long.";
	}
	if (!password.match(/[a-z]/)) {
		return "Password must contain at least one lowercase letter.";
	}
	if (!password.match(/[A-Z]/)) {
		return "Password must contain at least one uppercase letter.";
	}
	if (!password.match(/[0-9]/)) {
		return "Password must contain at least one number.";
	}
	if (!password.match(/[!@#$%^&*]/)) {
		return "Password must contain at least one special character [!@#$%^&*";
	}
}

export default ({ user }) => {
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarMessageSeverity, setSnackbarMessageSeverity] = useState("");
	const [showChangePasswordDialog, setShowChangePasswordDialog] =
		useState(false);
	const [useTemporaryPassword, setUseTemporaryPassword] = useState(false);
	const [temporaryPassword, setTemporaryPassword] = useState("");
	const [temporaryPasswordError, setTemporaryPasswordError] = useState(false);
	const [temporaryPasswordHelperText, setTemporaryPasswordHelperText] =
		useState(EMPTY_HELPER_TEXT);
	const [confirmPassword, setConfirmPassword] = useState("");
	const [confirmPasswordError, setConfirmPasswordError] = useState(false);
	const [confirmPasswordHelperText, setConfirmPasswordHelperText] =
		useState(EMPTY_HELPER_TEXT);
	const [showTemporaryPassword, setShowTemporaryPassword] = useState(false);

	const {
		requestPasswordReset,
		isSendingUser,
		hasSentUser,
		error: sendResetError,
	} = useSendReset();

	const {
		setTempPassword,
		isSettingUser,
		hasSetUser,
		error: setTempPasswordError,
	} = useSetTempPassword();

	const onSetTempPassword = () => {
		if (
			useTemporaryPassword &&
			(temporaryPasswordError ||
				confirmPasswordError ||
				!confirmPassword ||
				!temporaryPassword)
		) {
			return;
		}
		setTempPassword(user.altId, temporaryPassword);
	};

	useEffect(() => {
		if (sendResetError) {
			setSnackbarMessageSeverity("error");
			setSnackbarMessage(
				"Could not send password change request. Please try again later.",
			);
			setShowSnackbar(true);
		}
	}, [sendResetError]);

	useEffect(() => {
		if (setTempPasswordError) {
			setSnackbarMessageSeverity("error");
			setSnackbarMessage("Could not change password. Please try again later.");
			setShowSnackbar(true);
		}
	}, [setTempPasswordError]);

	useEffect(() => {
		if (!isSendingUser && hasSentUser && !sendResetError) {
			setSnackbarMessageSeverity("success");
			setSnackbarMessage("Password change request sent! ");
			setShowSnackbar(true);
		}
	}, [isSendingUser, hasSentUser, sendResetError]);

	useEffect(() => {
		if (!isSettingUser && hasSetUser && !setTempPasswordError) {
			setSnackbarMessageSeverity("success");
			setSnackbarMessage("Password changed! ");
			setShowSnackbar(true);
		}
	}, [isSettingUser, hasSetUser, setTempPasswordError]);

	useEffect(() => {
		if (confirmPassword !== temporaryPassword) {
			setConfirmPasswordHelperText("Passwords do not match");
			setConfirmPasswordError(true);
		} else {
			setConfirmPasswordHelperText(EMPTY_HELPER_TEXT);
			setConfirmPasswordError(false);
		}
	}, [confirmPassword, temporaryPassword]);

	useEffect(() => {
		const error = getTemporaryPasswordError(temporaryPassword);
		if (error) {
			setTemporaryPasswordError(true);
			setTemporaryPasswordHelperText(error);
		} else {
			setTemporaryPasswordError(false);
			setTemporaryPasswordHelperText(EMPTY_HELPER_TEXT);
		}
	}, [temporaryPassword]);

	return (
		<>
			<Grid
				container
				py={6}
				rowSpacing={4}
				columnSpacing={{ xl: 4, xs: 1 }}
				sx={{
					color: "black",
				}}
			>
				<Grid md={12} xs={12} px={6} className="fieldSectionTitle">
					Password
				</Grid>
				<Grid xs={1} />
				<Grid xs={5} sx={{ pl: 2 }}>
					<LoadingButton
						variant="outlined"
						loading={isSendingUser}
						disabled={hasSentUser}
						sx={{
							mb: 1,
						}}
						onClick={() => {
							setShowChangePasswordDialog(true);
						}}
					>
						{hasSentUser ? "Request Sent!" : "Send Reset"}
						<SendIcon sx={{ ml: 1 }} />{" "}
					</LoadingButton>
					<FormHelperText>
						Sends an email to the user with a link to reset their password. The
						link will expire after 24 hours.
					</FormHelperText>
				</Grid>
				<Grid xs={6} />

				<Grid md={1} xs={1} />
				<Grid md={5} xs={5} sx={{ pl: 2 }}>
					<FormControlLabel
						control={
							<Switch
								checked={useTemporaryPassword}
								onChange={(event) =>
									setUseTemporaryPassword(event.target.checked)
								}
								color="secondary"
							/>
						}
						label="Set Temporary Password"
					/>
					<FormHelperText>
						Enabling this will cause the user to be prompted to set their
						password after their next login. It is the responsibility of the
						manager to communicate the temporary password to the user since no
						password setup email will be issued.
					</FormHelperText>
					{useTemporaryPassword ? (
						<Stack pl={6} py={4} spacing={4} alignItems="end">
							<TextField
								label="Temporary Password"
								autoComplete="chrome-off"
								fullWidth
								onChange={(event) => setTemporaryPassword(event.target.value)}
								error={temporaryPasswordError}
								helperText={temporaryPasswordHelperText}
								type={showTemporaryPassword ? "text" : "password"}
								disabled={hasSetUser}
								InputProps={{
									autoComplete: "new-password",
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() =>
													setShowTemporaryPassword(!showTemporaryPassword)
												}
												onMouseDown={(event) => event.preventDefault()}
												edge="end"
											>
												{showTemporaryPassword ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<TextField
								label="Confirm Password"
								fullWidth
								onChange={(event) => setConfirmPassword(event.target.value)}
								error={confirmPasswordError}
								helperText={confirmPasswordHelperText}
								type={showTemporaryPassword ? "text" : "password"}
								disabled={hasSetUser}
								InputProps={{
									autoComplete: "new-password",
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() =>
													setShowTemporaryPassword(!showTemporaryPassword)
												}
												onMouseDown={(event) => event.preventDefault()}
												edge="end"
											>
												{showTemporaryPassword ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<LoadingButton
								variant="contained"
								loading={isSettingUser}
								disabled={hasSetUser}
								onClick={onSetTempPassword}
							>
								{hasSetUser ? "Password Changed" : "Change Password"}
							</LoadingButton>
						</Stack>
					) : (
						<></>
					)}
				</Grid>
				<Grid md={6} xs={6} />
			</Grid>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={showSnackbar}
				autoHideDuration={6000}
				onClose={() => setShowSnackbar(false)}
			>
				<Alert severity={snackbarMessageSeverity} sx={{ width: "100%" }}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
			<ChangePasswordDialog
				open={showChangePasswordDialog}
				onCancel={() => setShowChangePasswordDialog(false)}
				message="An email will be sent to the user with a link to reset their password. The link will expire after 24 hours."
				onContinue={() => {
					setShowChangePasswordDialog(false);
					requestPasswordReset(user.altId);
				}}
			/>
		</>
	);
};
