export async function handleGetAccessToken(
	getAccessTokenSilently,
	loginWithRedirect,
) {
	try {
		const token = await getAccessTokenSilently();
		return token;
	} catch (error) {
		await loginWithRedirect();
	}
}
