import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { updateUser } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isUpdatingUser, setIsUpdatingUser] = useState(false);
	const [error, setError] = useState(null);
	const [hasUpdatedUser, setHasUpdatedUser] = useState(false);
	const [response, setResponse] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	return {
		isUpdatingUser,
		hasUpdatedUser,
		response,
		error,
		updateUser: ({ userId, firstName, lastName, password, namespace }) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsUpdatingUser(true);
					return updateUser(token, {
						userId,
						firstName,
						lastName,
						password,
						namespace,
					});
				})
				.then((response) => {
					setResponse(response);
					setHasUpdatedUser(true);
				})
				.catch((e) => {
					setError(e);
					console.error(e);
				})
				.finally(() => {
					setIsUpdatingUser(false);
				});
		},
	};
};
