import Box from "@mui/material/Box";
import { useMemo, useSyncExternalStore } from "react";
import { useSearchParams } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import { store as meStore } from "../Stores/Me";

export default function RequiresPermissionContainer({ permission }) {
	const { permissions, isFetched, isFetching } = useSyncExternalStore(
		meStore.subscribeToMe,
		meStore.getMe,
	);
	const [searchParams] = useSearchParams();

	const namespace = searchParams.get("n");

	const isAuthorized = useMemo(
		() =>
			permissions.find(
				(p) => p.name === permission && p.definition.namespace === namespace,
			),
		[permission, permissions, namespace],
	);

	function render() {
		if (isAuthorized) {
			return <Outlet />;
		}

		if (isFetching) {
			return (
				<Box
					sx={{
						position: "absolute",
						top: "50vh",
						left: "50vw",
						transform: "translate(-50%, -50%)",
					}}
				>
					<Box>
						<BounceLoader color="#44ABDF" size={80} />
					</Box>
				</Box>
			);
		}

		return (
			<Box
				height="100%"
				width="100%"
				alignItems="center"
				display="flex"
				flexDirection="column"
				justifyContent="center"
			>
				<h2>Page Not Found</h2>
				<p>
					Sorry, the page you are looking for does not exist or cannot be
					accessed. If the problem persists, contact your administrator.
				</p>
			</Box>
		);
	}

	return render();
}
