import { useMemo, useSyncExternalStore } from "react";
import { useSearchParams } from "react-router-dom";
import { store as meStore } from "../Stores/Me";

export default function RequiresPermission({
	permission,
	children,
	namespace,
}) {
	const { permissions } = useSyncExternalStore(
		meStore.subscribeToMe,
		meStore.getMe,
	);
	const [searchParams] = useSearchParams();

	const namespaceParam = searchParams.get("n");

	const selectedNamespace = namespace ?? namespaceParam;

	const isAuthorized = useMemo(
		() =>
			permissions.find(
				(p) =>
					p.name === permission && p.definition.namespace === selectedNamespace,
			),
		[permission, permissions, selectedNamespace],
	);

	function render() {
		if (isAuthorized) {
			return <>{children}</>;
		}

		return <></>;
	}

	return render();
}
