import { useAuth0 } from "@auth0/auth0-react";
import { LookerEmbedSDK } from "@looker/embed-sdk";
import { useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import {
	lookerAcquireSession,
	lookerGenerateTokens,
} from "../../../../Clients/NodeServer";
import RefreshDialog from "../../../../Components/RefreshDialog";
import { handleGetAccessToken } from "../../../../Helpers/auth";

function LookerEmbed({
	lookerId,
	showLoading,
	filters,
	onFilterChange,
	sudodAs,
}) {
	const [isLoading, setIsLoading] = useState(false);
	const iframeContainerRef = useRef(null);
	const lookerDashboardInstance = useRef(null);
	const [showRefreshDialog, setShowRefreshDialog] = useState(false);
	const theme = useTheme();
	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (!lookerId) {
			return;
		}
		setIsLoading(true);

		async function start() {
			let api_token;
			let navigation_token;

			const aquireSession = async () => {
				const access_token = await handleGetAccessToken(
					getAccessTokenSilently,
					loginWithRedirect,
				);

				try {
					const resp = await lookerAcquireSession(access_token, sudodAs);
					api_token = resp.data.api_token;
					navigation_token = resp.data.navigation_token;
					return resp.data;
				} catch (error) {
					console.error(error);
					//TODO maybe we should do something better here?
					throw new Error("acquire embed session failed");
				}
			};

			const generateTokens = async () => {
				const access_token = await handleGetAccessToken(
					getAccessTokenSilently,
					loginWithRedirect,
				);
				try {
					const resp = await lookerGenerateTokens(
						access_token,
						api_token,
						navigation_token,
						sudodAs,
					);

					api_token = resp.data.api_token;
					navigation_token = resp.data.navigation_token;
					return resp.data;
				} catch (error) {
					if (error.status === 400) {
						return { session_reference_token_ttl: 0 };
					}
					//TODO maybe we should do something better here?
					throw new Error("generate tokens failed");
				}
			};

			LookerEmbedSDK.initCookieless(
				import.meta.env.VITE_LOOKER_HOST,
				aquireSession,
				generateTokens,
			);

			lookerDashboardInstance.current =
				await LookerEmbedSDK.createDashboardWithId(lookerId)
					.withDialogScroll()
					.withAllowAttr("fullscreen")
					.withFilters(filters)
					.appendTo(iframeContainerRef.current)
					.on("dashboard:run:complete", (event) => {
						const iframe = iframeContainerRef.current?.querySelector("iframe");
						if (iframe) {
							iframeContainerRef.current.style.display = "inline"; // Ensuring the iframe is visible only after loading
							iframe.style.width = "100%"; // Ensuring the iframe is full width
							iframe.style.height = "100%";
							iframe.style.overflow = "hidden"; // Ensuring the iframe is not scrollable
							iframe.scrolling = "no";
						}
						setIsLoading(false);
					})
					.on("dashboard:run:start", (event) => {
						setIsLoading(true);
					})
					.on("dashboard:filters:changed", onFilterChange)
					.on("session:tokens", (event) => {
						if (event.session_reference_token_ttl === 0) {
							setShowRefreshDialog(true);
						}
					})
					.build()
					.connect();
		}

		start();

		return () => {
			const lookerIframe = iframeContainerRef.current?.querySelector("iframe");
			if (lookerIframe) {
				iframeContainerRef.current.style.display = "none";
				lookerIframe.remove();
			}
		};
	}, [lookerId]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (lookerDashboardInstance.current) {
			lookerDashboardInstance.current.updateFilters(filters);
			lookerDashboardInstance.current.run();
		}
	}, [JSON.stringify(filters)]);

	return (
		<div id="page-container" style={{ height: "100%", width: "100%" }}>
			<div style={{ display: "none" }} ref={iframeContainerRef} />
			{isLoading || showLoading ? (
				<div
					style={{
						display: "flex",
						width: "100%",
						height: "100%",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<BounceLoader color={theme.palette.primary.loader} size={80} />
				</div>
			) : (
				<></>
			)}
			<RefreshDialog
				open={showRefreshDialog}
				countFrom={20}
				onClose={() => setShowRefreshDialog(false)}
			/>
		</div>
	);
}

export default LookerEmbed;

LookerEmbed.propTypes = {
	lookerId: PropTypes.string,
	showLoading: PropTypes.bool,
};
