import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { fetchUser } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isFetchingUser, setIsFetchingUser] = useState(false);
	const [hasFetchedUser, setHasFetchedUser] = useState(false);
	const [response, setResponse] = useState(null);
	const [error, setError] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	return {
		isFetchingUser,
		hasFetchedUser,
		response,
		error,
		fetchUser: (userId) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsFetchingUser(true);
					return fetchUser(token, userId);
				})
				.then((response) => {
					setResponse(response);
					setHasFetchedUser(true);
				})
				.catch((e) => {
					setHasFetchedUser(true); // for now so we don't try and refetch
					setError(e);
					console.error(e);
				})
				.finally(() => setIsFetchingUser(false));
		},
	};
};
