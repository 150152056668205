import axios from "axios";
import { pickBy } from "lodash";

//TODO remove X-Forwarded-Proto when deploying, this is only intended to aid local development
const axiosWithSudo = axios.create({
	baseURL: import.meta.env.VITE_API_URL,
	headers: { "X-Forwarded-Proto": "https" },
});

const sudodAs = window.sessionStorage.getItem("sudodAs");

axiosWithSudo.interceptors.request.use((config) => {
	if (sudodAs) {
		config.params = {
			...config.params,
			assumeUserId: sudodAs,
		};
	}
	return config;
});

axiosWithSudo.interceptors.response.use(
	(response) => response,
	(err) => {
		//This case can happen if the user is delete while a user is still sudod in as the deleted user
		if (err.response?.data.message.includes("cannot assume user")) {
			window.sessionStorage.removeItem("sudodAs");
			window.location.reload();
			console.error(err.response?.data.message);
		}
	},
);

export function lookerAcquireSession(token) {
	return axiosWithSudo.get("/looker/aquireSession", {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		withCredentials: true,
	});
}

export function lookerGenerateTokens(token, apiToken, navigationToken) {
	return axiosWithSudo.put(
		"/looker/generateTokens",
		{
			api_token: apiToken,
			navigation_token: navigationToken,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
			withCredentials: true,
		},
	);
}

export function fetchMe(token) {
	return axiosWithSudo.get("/user/me", {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export function getUsers(
	token,
	{ namespace, pageSize, nextToken: next, query },
) {
	const santiziedQueryParams = pickBy({
		namespace,
		pageSize,
		next,
		query,
	});

	return axiosWithSudo.get("/users", {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: santiziedQueryParams,
	});
}

export function updateMe(token, { firstName, lastName, namespace }) {
	return axiosWithSudo.put(
		"/user/me",
		{
			firstName,
			lastName,
			namespace,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function updateUser(
	token,
	{ userId, firstName, lastName, password, namespace },
) {
	return axiosWithSudo.put(
		`/users/${userId}`,
		{
			firstName,
			lastName,
			namespace,
			password,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function createUser(
	token,
	{ firstName, lastName, email, namespace },
	{ skipPasswordEmail },
) {
	const queryParams = pickBy({
		skipPasswordEmail,
	});
	return axiosWithSudo.post(
		"/users",
		{
			firstName,
			lastName,
			namespace,
			email,
			roles: [
				{
					name: "lookerViewer",
					namespace: namespace,
				},
			],
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
			params: queryParams,
		},
	);
}

export function deleteUser(token, userId) {
	return axiosWithSudo.delete(`/users/${userId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export function fetchUser(token, userId) {
	return axiosWithSudo.get(`/users/${userId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export function requestMyPasswordReset(token) {
	return axiosWithSudo.put("/user/me/changePassword", null, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export function requestPasswordReset(token, userId) {
	return axiosWithSudo.put(`/users/${userId}/emailReset`, null, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export function setTemporaryPassword(token, userId, password) {
	return axiosWithSudo.put(
		`/users/${userId}/setTempPassword`,
		{
			password,
		},
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);
}

export function getDashboardUrl(token, slug) {
	return axiosWithSudo.get(`/looker/dashboards/${slug}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}

export function getDashboards(token) {
	return axiosWithSudo.get("/looker/dashboards", {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
}
