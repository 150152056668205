import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { deleteUser } from "../Clients/NodeServer";
import { handleGetAccessToken } from "../Helpers/auth";

export default () => {
	const [isDeletingUser, setIsDeletingUser] = useState(false);
	const [error, setError] = useState(null);
	const [hasDeletedUser, setHasDeletedUser] = useState(false);
	const [response, setResponse] = useState(null);
	const [deletedUserIds, setDeletedUserIds] = useState([]);
	const [deletingUserId, setDeletingUserId] = useState([]);
	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	return {
		isDeletingUser,
		hasDeletedUser,
		deletedUserIds,
		deletingUserId,
		response,
		error,
		deleteUser: (userId) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsDeletingUser(true);
					setDeletingUserId(userId);
					return deleteUser(token, userId);
				})
				.then((response) => {
					setResponse(response);
					setHasDeletedUser(true);
					setDeletedUserIds((last) => [userId, ...last]);
				})
				.catch((e) => {
					setError(e);
					console.error(e);
				})
				.finally(() => {
					setDeletingUserId(null);
					setIsDeletingUser(false);
				});
		},
	};
};
