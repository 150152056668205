import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default (props) => {
	return (
		<Dialog
			open={props.open}
			onClose={props.onCancel}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{"Reset Password?"}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{props.message
						? props.message
						: "An email will be sent to you with a link to reset your password. The link will expire after 24 hours."}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onCancel} variant="text">
					Cancel
				</Button>
				<Button onClick={props.onContinue} autoFocus variant="contained">
					Continue
				</Button>
			</DialogActions>
		</Dialog>
	);
};
