import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { requestPasswordReset } from "../../Clients/NodeServer";
import { handleGetAccessToken } from "../../Helpers/auth";

export default () => {
	const [isSendingUser, setIsSendingUser] = useState(false);
	const [error, setError] = useState(null);
	const [hasSentUser, setHasSentUser] = useState(false);
	const [response, setResponse] = useState(null);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	return {
		isSendingUser,
		hasSentUser,
		response,
		error,
		requestPasswordReset: (userId) => {
			return handleGetAccessToken(getAccessTokenSilently, loginWithRedirect)
				.then((token) => {
					setIsSendingUser(true);
					return requestPasswordReset(token, userId);
				})
				.then((response) => {
					setResponse(response);
					setHasSentUser(true);
				})
				.catch((e) => {
					setError(e);
					console.error(e);
				})
				.finally(() => {
					setIsSendingUser(false);
				});
		},
	};
};
