import Box from "@mui/material/Box";
import { useEffect, useState, useSyncExternalStore } from "react";
import "../styles.css";
import { useAuth0 } from "@auth0/auth0-react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate, useSearchParams } from "react-router-dom";
import { handleGetAccessToken } from "../../Helpers/auth";
import { isValidEmail } from "../../Helpers/validators";
import {
	actions as usersActions,
	store as usersStore,
} from "../../Stores/Users";
// https://mui.com/material-ui/react-text-field/#helper-text
const EMPTY_HELPER_TEXT = " ";

//these rules come from auth0 guidelines
function getTemporaryPasswordError(password) {
	if (password.length < 8) {
		return "Password must be at least 8 characters long.";
	}
	if (!password.match(/[a-z]/)) {
		return "Password must contain at least one lowercase letter.";
	}
	if (!password.match(/[A-Z]/)) {
		return "Password must contain at least one uppercase letter.";
	}
	if (!password.match(/[0-9]/)) {
		return "Password must contain at least one number.";
	}
	if (!password.match(/[!@#$%^&*]/)) {
		return "Password must contain at least one special character [!@#$%^&*";
	}
}

export default () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState(false);
	const [emailHelperText, setEmailHelperText] = useState(EMPTY_HELPER_TEXT);
	const [namespace, setNamespace] = useState("");
	const [temporaryPassword, setTemporaryPassword] = useState("");
	const [temporaryPasswordError, setTemporaryPasswordError] = useState(false);
	const [temporaryPasswordHelperText, setTemporaryPasswordHelperText] =
		useState(EMPTY_HELPER_TEXT);
	const [confirmPassword, setConfirmPassword] = useState("");
	const [confirmPasswordError, setConfirmPasswordError] = useState(false);
	const [confirmPasswordHelperText, setConfirmPasswordHelperText] =
		useState(EMPTY_HELPER_TEXT);
	const [useTemporaryPassword, setUseTemporaryPassword] = useState(false);
	const [firstNameHelperText, setFirstNameHelperText] =
		useState(EMPTY_HELPER_TEXT);
	const [firstNameError, setFirstNameError] = useState(false);
	const [lastNameHelperText, setLastNameHelperText] =
		useState(EMPTY_HELPER_TEXT);
	const [lastNameError, setLastNameError] = useState(false);
	const [showTemporaryPassword, setShowTemporaryPassword] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);

	const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

	const {
		isCreating: isCreatingUser,
		isCreated: hasCreatedUser,
		createError,
	} = useSyncExternalStore(usersStore.subscribeToUsers, usersStore.getUsers);

	useEffect(() => {
		if (createError?.code === "EMAIL_IN_USE") {
			setEmailHelperText("Email address is already in use");
			setEmailError(true);
		} else if (createError) {
			setShowSnackbar(true);
		}
		usersActions.resetCreateUserState();
	}, [createError]);

	useEffect(() => setNamespace(searchParams.get("n")), [searchParams]);

	useEffect(() => {
		if (confirmPassword !== temporaryPassword) {
			setConfirmPasswordHelperText("Passwords do not match");
			setConfirmPasswordError(true);
		} else {
			setConfirmPasswordHelperText(EMPTY_HELPER_TEXT);
			setConfirmPasswordError(false);
		}
	}, [confirmPassword, temporaryPassword]);

	useEffect(() => {
		const error = getTemporaryPasswordError(temporaryPassword);
		if (error) {
			setTemporaryPasswordError(true);
			setTemporaryPasswordHelperText(error);
		} else {
			setTemporaryPasswordError(false);
			setTemporaryPasswordHelperText(EMPTY_HELPER_TEXT);
		}
	}, [temporaryPassword]);

	useEffect(() => {
		//TODO state should just be kept in this component, no need for store
		if (hasCreatedUser) {
			usersActions.resetState();
			navigate(`/users?n=${namespace}`);
		}
	}, [hasCreatedUser, namespace, navigate]);

	const onSave = () => {
		let hasErr = false;
		if (!firstName) {
			setFirstNameHelperText("First name is required");
			setFirstNameError(true);
			hasErr = true;
		}
		if (!lastName) {
			setLastNameHelperText("Last name is required");
			setLastNameError(true);
			hasErr = true;
		}
		if (!email) {
			setEmailHelperText("Email is required");
			setEmailError(true);
			hasErr = true;
		}
		if (
			useTemporaryPassword &&
			(temporaryPasswordError ||
				confirmPasswordError ||
				!confirmPassword ||
				!temporaryPassword)
		) {
			hasErr = true;
		}

		if (!hasErr) {
			handleGetAccessToken(getAccessTokenSilently, loginWithRedirect).then(
				(token) => {
					usersActions.createUser(token, {
						firstName,
						lastName,
						email,
						namespace,
						password: temporaryPassword ?? null,
					});
				},
			);
		}
	};
	const onEmailChange = (e) => {
		setEmail(e.target.value);

		if (!e.target.value) {
			setEmailHelperText("Email is required");
			setEmailError(true);
		} else if (!isValidEmail(e.target.value)) {
			setEmailHelperText("Invalid email address");
			setEmailError(true);
		} else if (e.target.value) {
			setEmailError(false);
			setEmailHelperText(EMPTY_HELPER_TEXT);
		}
	};
	return (
		<>
			<Box px={4} borderBottom="1px solid #dbdbdb">
				<Box className="pageTitle">Add User</Box>
			</Box>

			<Grid
				container
				py={6}
				rowSpacing={4}
				columnSpacing={{ xl: 4, xs: 1 }}
				sx={{
					color: "black",
				}}
			>
				<Grid md={12} xs={12} px={6} className="fieldSectionTitle">
					User Details
				</Grid>
				<Grid md={1} xs={1} />
				<Grid md={5} xs={10}>
					<TextField
						label="First Name"
						value={firstName}
						onChange={(e) => {
							setFirstName(e.target.value);
							if (e.target.value) {
								setFirstNameError(false);
								setFirstNameHelperText(EMPTY_HELPER_TEXT);
							} else {
								setFirstNameHelperText("First name is required");
								setFirstNameError(true);
							}
						}}
						fullWidth
						helperText={firstNameHelperText}
						error={firstNameError}
						required
					/>
				</Grid>
				<Grid md={6} xs={1} />
				<Grid md={1} xs={1} />
				<Grid md={5} xs={10}>
					<TextField
						label="Last Name"
						value={lastName}
						onChange={(e) => {
							setLastName(e.target.value);
							if (e.target.value) {
								setLastNameError(false);
								setLastNameHelperText(EMPTY_HELPER_TEXT);
							} else {
								setLastNameHelperText("Last name is required");
								setLastNameError(true);
							}
						}}
						fullWidth
						required
						helperText={lastNameHelperText}
						error={lastNameError}
					/>
				</Grid>
				<Grid md={6} xs={1} />
				<Grid md={1} xs={3} />
				<Grid md={5} xs={9}>
					<TextField
						label="Email"
						value={email}
						onChange={onEmailChange}
						fullWidth
						required
						error={emailError}
						helperText={emailHelperText}
					/>
				</Grid>
				<Grid md={6} xs={1} />
				<Grid md={1} xs={3} />
				<Grid
					md={5}
					xs={9}
					sx={{
						color: "black",
					}}
				>
					<TextField
						label="Organtization"
						value={namespace}
						onChange={() => {}}
						fullWidth
						helperText={EMPTY_HELPER_TEXT}
						disabled
					/>
					<FormHelperText> </FormHelperText>
				</Grid>
				<Grid md={6} xs={1} />
				<Grid md={1} xs={3} />
				<Grid
					md={5}
					xs={9}
					sx={{
						pt: 0,
						color: "black",
					}}
				>
					<FormControlLabel
						value="top"
						sx={{
							m: 0,
							alignItems: "start",
						}}
						control={
							<Switch
								checked={useTemporaryPassword}
								onChange={(event) =>
									setUseTemporaryPassword(event.target.checked)
								}
								color="secondary"
							/>
						}
						label="Set Temporary Password"
						labelPlacement="top"
					/>
					<FormHelperText>
						Enabling this will cause the user to be prompted to set their
						password after their next login. It is the responsibility of the
						manager to communicate the temporary password to the user since no
						password setup email will be issued.
					</FormHelperText>
					{useTemporaryPassword ? (
						<Stack pl={6} py={4} spacing={4}>
							<TextField
								label="Temporary Password"
								autoComplete="chrome-off"
								fullWidth
								onChange={(event) => setTemporaryPassword(event.target.value)}
								error={temporaryPasswordError}
								helperText={temporaryPasswordHelperText}
								type={showTemporaryPassword ? "text" : "password"}
								InputProps={{
									autoComplete: "new-password",
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() =>
													setShowTemporaryPassword(!showTemporaryPassword)
												}
												onMouseDown={(event) => event.preventDefault()}
												edge="end"
											>
												{showTemporaryPassword ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<TextField
								label="Confirm Password"
								fullWidth
								onChange={(event) => setConfirmPassword(event.target.value)}
								error={confirmPasswordError}
								helperText={confirmPasswordHelperText}
								type={showTemporaryPassword ? "text" : "password"}
								InputProps={{
									autoComplete: "new-password",
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={() =>
													setShowTemporaryPassword(!showTemporaryPassword)
												}
												onMouseDown={(event) => event.preventDefault()}
												edge="end"
											>
												{showTemporaryPassword ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</Stack>
					) : (
						<></>
					)}
				</Grid>
				<Grid md={6} xs={0} />
				<Grid md={1} xs={3} />
				<Grid md={1} xs={3}>
					<LoadingButton
						loading={isCreatingUser}
						variant="contained"
						fullWidth
						onClick={onSave}
					>
						Save
					</LoadingButton>
				</Grid>
				<Grid md={4} xs={6} />
				<Grid md={6} xs={0} />
			</Grid>
			<Snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				open={showSnackbar}
				autoHideDuration={6000}
				onClose={() => setShowSnackbar(false)}
			>
				<Alert severity="error" sx={{ width: "100%" }}>
					{"Something went wrong. Please try again later."}
				</Alert>
			</Snackbar>
		</>
	);
};
